/**
 * Wraps a fetch request with retry logic on exceptions, which is useful for
 * spotty connections that may fail intermittently.
 */ function wrapWithRetry(fetch) {
    return async function(url, ...args) {
        let retries = 0;
        while(true){
            try {
                return await fetch(url, ...args);
            } catch (e) {
                if (retries++ > 3) throw e;
            }
        }
    };
}

export { wrapWithRetry as w };
